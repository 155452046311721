import React from 'react';
import { Grid2, Typography, styled } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { ButtonComponent, ModalComponent, RadioComponent } from '../../';

import { AppContext } from '../../../services';

const StyledContainer = styled(Grid2)`
    padding: 25px 25px !important;
`;

const StyledPrimaryText = styled(Typography)`
    font-size: 14px !important;
    font-weight: 500 !important;
    margin: 0 !important;
`;

function RadioGroup(props) {
    const { handleSelect = () => {}, value, valueSelector, options = [] } = props;
    return (
        <Grid2 container sx={{ flexGrow: 1 }} spacing={1}>
            {options.map((option, index) => (
                <Grid2 key={`${uuidv4()}-radio-item-${index}`} size={{ xs: 12 }}>
                    <RadioComponent
                        label={option.label}
                        checked={valueSelector ? value === option['value'][valueSelector] : value === option.value}
                        handleSelect={() => handleSelect(option.value)}
                    />
                </Grid2>
            ))}
        </Grid2>
    );
}

function SelectionGroup(props) {
    const { options = [], requirement, title = '', handleSelect = () => {}, value, valueSelector = null } = props;

    return (
        (options.length && requirement && (
            <Grid2 size={{ xs: 12 }}>
                <Grid2 container sx={{ flexGrow: 1 }}>
                    <>
                        <Grid2 container sx={{ flexGrow: 1 }} spacing={2}>
                            <Grid2 size={{ xs: 12 }}>
                                <StyledPrimaryText>{title}</StyledPrimaryText>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    {RadioGroup({
                                        options,
                                        value,
                                        handleSelect,
                                        valueSelector
                                    })}
                                </>
                            </Grid2>
                        </Grid2>
                    </>
                </Grid2>
            </Grid2>
        )) ||
        null
    );
}

const initialState = {
    plan: null,
    geoCoverage: null,
    transportMode: null,
    maxTripDuration: null,
    durationTypeCd: null,
    durationDays: null,
    startDate: null,
    endDate: null
};

function PlanModal(props) {
    const { open, handleClose = () => {} } = props;
    const { state, setState, maintenance } = React.useContext(AppContext);
    const [internalState, setInternalState] = React.useState(initialState);

    React.useEffect(() => {
        initialize();

        return () => {
            setInternalState(() => initialState);
        };
    }, [open]);

    function initialize() {
        const {
            plan,
            geoCoverage,
            transportMode,
            maxTripDuration,
            durationTypeCd,
            durationDays,
            startDate,
            endDate,
            type,
            groupSize
        } = state;

        setInternalState(() => ({
            plan,
            geoCoverage,
            transportMode,
            maxTripDuration,
            durationTypeCd,
            durationDays,
            startDate,
            endDate,
            type,
            groupSize
        }));
    }

    function handleSelect(target, value, source = null) {
        setInternalState((p) => ({ ...p, [target]: value }));

        if (source === 'geographicalCoverage') {
            const maintenanceData = maintenance?.transportMode.filter(
                (f) =>
                    f.condition === internalState.type && f.condition2 === internalState.plan && f.condition3 === value
            );

            if (maintenanceData.length === 1) {
                setInternalState((p) => ({ ...p, ['transportMode']: maintenanceData[0].value }));
            } else {
                setInternalState((p) => ({ ...p, ['transportMode']: undefined }));
            }
        }
    }

    function handleSubmitForm() {
        setState((s) => ({ ...s, ...internalState, premAmount: null, startDate: null, endDate: null }));
        handleClose();
    }

    function handleResetForm() {
        setInternalState((s) => ({ ...s, ...initialState }));
    }

    return (
        <ModalComponent
            open={open}
            handleClose={handleClose}
            sx={{
                maxWidth: {
                    xs: '80vw',
                    md: '30vw'
                },
                minWidth: {
                    xs: '95vw',
                    md: '30vw'
                },
                maxHeight: {
                    xs: '85vh',
                    md: '70vh'
                },
                overflow: {
                    xs: 'auto',
                    md: 'auto'
                }
            }}
        >
            <StyledContainer container rowSpacing={2}>
                {SelectionGroup({
                    options: (maintenance?.plans || []).filter((f) => f.condition === internalState.type),
                    requirement: internalState.type,
                    title: 'Your Plan',
                    handleSelect: (v) => {
                        handleSelect('plan', v);
                        handleSelect('geoCoverage', undefined);
                        handleSelect('transportMode', undefined);
                        handleSelect('durationTypeCd', undefined);
                        handleSelect('startDate', undefined);
                        handleSelect('endDate', undefined);
                    },
                    value: internalState.plan
                })}

                {SelectionGroup({
                    options: (maintenance?.geoCoverage || []).filter(
                        (f) => f.condition === internalState.type && f.condition2 === internalState.plan
                    ),
                    requirement: internalState.plan,
                    title: 'Geographical Coverage',
                    handleSelect: (v) => {
                        handleSelect('geoCoverage', v, 'geographicalCoverage');
                        // handleSelect('transportMode', undefined);
                        handleSelect('durationTypeCd', undefined);
                        handleSelect('startDate', undefined);
                        handleSelect('endDate', undefined);
                    },
                    value: internalState.geoCoverage
                })}

                {SelectionGroup({
                    options: (maintenance?.transportMode || []).filter(
                        (f) =>
                            f.condition === internalState.type &&
                            f.condition2 === internalState.plan &&
                            f.condition3 === internalState.geoCoverage
                    ),
                    requirement: internalState.type && internalState.plan && internalState.geoCoverage,
                    title: 'Mode of Transport',
                    handleSelect: (v) => {
                        handleSelect('transportMode', v);
                        handleSelect('durationTypeCd', undefined);
                        handleSelect('startDate', undefined);
                        handleSelect('endDate', undefined);
                    },
                    value: internalState.transportMode
                })}

                {SelectionGroup({
                    options: (maintenance?.maxTripDuration || []).filter((f) => {
                        const type = internalState?.type;
                        const groupSize = internalState?.groupSize;
                        const plan = internalState?.plan;
                        const geoCoverage = internalState?.geoCoverage;
                        const transportMode = internalState?.transportMode;

                        const conditions = (maintenance?.travelDurationTypePerDetails || [])
                            .filter(
                                (_f) =>
                                    !!(
                                        type === _f.travelTypeCd &&
                                        groupSize === _f.travelGroupSizeCd &&
                                        plan === _f.travelPlanCd &&
                                        geoCoverage === _f.travelGeoCoverageCd &&
                                        transportMode === _f.travelTransportModeCd
                                    )
                            )
                            .map((g) => g.durationTypeCd);

                        return !!conditions.includes(f.condition);
                    }),
                    requirement:
                        !!(
                            internalState?.type &&
                            internalState?.groupSize &&
                            internalState?.plan &&
                            internalState?.geoCoverage &&
                            internalState?.transportMode
                        ) || false,
                    title: 'Maximum Trip Duration',
                    handleSelect: (v) => {
                        handleSelect('maxTripDuration', v.durationSelectionCd);
                        handleSelect('durationTypeCd', v.durationTypeCd);
                        handleSelect('durationDays', v.durationLimit);
                        handleSelect('startDate', undefined);
                        handleSelect('endDate', undefined);
                    },
                    value: internalState.maxTripDuration,
                    valueSelector: 'durationSelectionCd'
                })}

                <Grid2 size={{ xs: 12 }}>
                    <ButtonComponent handleClick={handleSubmitForm} color="#fff" background="#ff6002" label="CONFIRM" />
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <ButtonComponent handleClick={handleResetForm} label="RESET" />
                </Grid2>
            </StyledContainer>
        </ModalComponent>
    );
}

export default PlanModal;
