function getDurationDays(data, maintenance) {
    const {
        type: travelTypeCd,
        groupSize: travelGroupSizeCd,
        plan: travelPlanCd,
        geoCoverage: travelGeoCoverageCd,
        transportMode: travelTransportModeCd,
        durationTypeCd: durationTypeCd,
        maxTripDuration: durationSelectionCd,
        durationDays: duration
    } = data;

    try {
        let _durationTypeCd;
        let _durationSelectionCd;
        let _duration = 0;

        if (!durationTypeCd) {
            const _d = maintenance?.travelDurationTypePerDetails.find(
                (f) =>
                    f.travelTypeCd === travelTypeCd &&
                    f.travelGroupSizeCd === travelGroupSizeCd &&
                    f.travelPlanCd === travelPlanCd &&
                    f.travelGeoCoverageCd === travelGeoCoverageCd &&
                    f.travelTransportModeCd === travelTransportModeCd
            );

            _durationTypeCd = _d.durationTypeCd;
        }

        if (!durationSelectionCd) {
            const _ds = maintenance?.travelDurationSelection.find((f) => f.durationTypeCd === _durationTypeCd);
            _durationSelectionCd = _ds.durationSelectionCd;
            _duration = _ds.durationLimit || 365;
        } else {
            _duration = duration;
        }

        return _duration;
    } catch (error) {
        return 0;
    }
}

export default getDurationDays;
