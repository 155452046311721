import React from 'react';
import { Grid2, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import numeral from 'numeral';

import { ButtonComponent } from '../../utilities';

import { AppContext } from '../../../services';

function AccordionComponent(props) {
    const { id, summary, details, amount } = props;
    const [expand, setExpand] = React.useState(false);
    return (
        <Accordion
            key={id}
            disableGutters
            elevation={0}
            sx={{ border: 'none', boxShadow: 'none' }}
            onChange={() => {
                setExpand(!expand);
            }}
        >
            <AccordionSummary
                expandIcon={
                    details ? (
                        expand ? (
                            <ExpandMore />
                        ) : (
                            <ExpandMore />
                        )
                    ) : (
                        <ExpandMore sx={{ color: 'transparent !important' }} />
                    )
                }
                id={id}
            >
                <Grid2 container sx={{ flexGrow: 1 }} justifyContent="space-between">
                    <Grid2>
                        <Typography fontSize="22px" fontWeight={500} sx={{whiteSpace: 'pre-line'}}>
                             {
                                summary
                                    .replace(/(Misconnections, Overbookings)/, '\n$1')
                                    .replace(/(- Cashless - International)/, '\n$1')
                                }
                        </Typography>
                    </Grid2>
                    <Grid2>
                        <Typography fontSize="22px" fontWeight={500}>
                            {amount ? numeral(amount).format('0,0') : 'Covered'}
                        </Typography>
                    </Grid2>
                </Grid2>
            </AccordionSummary>
            {details && (
                <AccordionDetails sx={{ padding: 0 }}>
                    <Grid2 container sx={{ flexGrow: 1, padding: '0 39px 0 16px' }} justifyContent="space-between">
                        <Grid2>
                            <Typography fontSize="18px" fontWeight={500}>
                                {details}
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Typography fontSize="18px" fontWeight={500}>
                                {amount ? numeral(amount).format('0,0') : 'Covered'}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </AccordionDetails>
            )}
        </Accordion>
    );
}

const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    } else {
        console.warn(`Element with ID '${id}' not found.`);
    }
};

function QuotationScaffold() {
    const { setConfig, maintenance, state } = React.useContext(AppContext);
    // const [expand, setExpand] = React.useState('');

    const filteredDurationDetails = (maintenance?.travelDurationTypePerDetails || []).filter(
        (f) =>
            f.travelTypeCd === state.type &&
            f.travelPlanCd === state.plan &&
            f.travelGeoCoverageCd === state.geoCoverage &&
            f.travelTransportModeCd === state.transportMode
    );

    const policyUrl =
        filteredDurationDetails.length > 0 ? filteredDurationDetails[0].url : 'https://bit.ly/AyalaTravelTC';

    return (
        <Grid2 container columnSpacing={6} rowSpacing={3} justifyContent="center">
            <Grid2 size={{ xs: 10 }} sx={{ marginTop: '36px' }}>
                <Typography textTransform="uppercase" textAlign="center" fontSize="26px" fontWeight={600}>
                    What's included in your travel insurance plan?
                </Typography>
                <Typography textAlign="center" fontSize="22px">
                    Here are just a few of the great benefits you'll enjoy on your trip.
                </Typography>
            </Grid2>
            <Grid2 size={{ xs: 11 }}>
                {(maintenance?.travelPerils || [])
                    .filter(
                        (f) =>
                            f.travelTypeCd === state.type &&
                            f.travelPlanCd === state.plan &&
                            f.travelGeoCoverageCd === state.geoCoverage &&
                            f.travelTransportModeCd === state.transportMode
                    )
                    .sort((a, b) => a.sequenceNo - b.sequenceNo)
                    .map((m, i) =>
                        AccordionComponent({
                            id: `perils-accordion-${i}`,
                            summary: m.travelPerilName,
                            details: m.travelPerilDesc,
                            amount: m.travelPerilAmt
                        })
                    )}
            </Grid2>

            <Grid2 size={{ xs: 10 }}>
                <>
                    <Grid2 container sx={{ flexGrow: 1 }} gap={2}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography textAlign="center">
                                For full details and terms, please refer to the{' '}
                                <strong>
                                    <a href={policyUrl} target="_blank" style={{ color: 'black' }}>
                                        policy terms & conditions
                                    </a>
                                </strong>{' '}
                                which shall prevail over the summary presented.
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }} justifyContent="end" gap={3}>
                                    {/* <Grid2>
                                        <ButtonComponent
                                            label="SEE FULL LIST OF BENEFITS"
                                            handleClick={() => window.open('https://bit.ly/AyalaTravelTC', '_blank')}
                                        />
                                    </Grid2> */}
                                    <Grid2>
                                        <ButtonComponent
                                            color="#fff"
                                            background="#ff6002"
                                            label="APPLY NOW"
                                            handleClick={() => {
                                                setConfig((c) => ({ ...c, page: 'form' }));
                                            }}
                                        />
                                    </Grid2>
                                </Grid2>
                            </>
                        </Grid2>
                    </Grid2>
                </>
            </Grid2>
        </Grid2>
    );
}

export default QuotationScaffold;
