import React, { useEffect } from 'react';
import { Grid2, Typography } from '@mui/material';

import { ButtonComponent } from '../../utilities';

import { AppContext } from '../../../services';

const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

function TransactionView() {
    const { config, setConfig } = React.useContext(AppContext);

    useEffect(() => {
        if (
            config.applicationStatus === 'WOP' ||
            config.applicationStatus === 'WP' ||
            config.applicationStatus === 'F'
        ) {
            setTimeout(() => {
                scrollToElement('status'); // Scroll to footer after the drawer is closed
            }, 100);
        }
    }, [config.applicationStatus]);

    return (
        <>
            <Grid2
                container
                alignContent="center"
                justifyContent="center"
                spacing={5}
                position="relative"
                overflow="hidden"
                id="status"
            >
                {config.applicationStatus === 'WOP' && (
                    <Grid2 size={{ xs: 11, md: 8 }} style={{ zIndex: '999' }}>
                        <>
                            <Grid2
                                container
                                flexGrow={1}
                                spacing={3}
                                sx={{
                                    paddingTop: {
                                        xs: '20px',
                                        md: '60px'
                                    },
                                    paddingBottom: {
                                        xs: '20px',
                                        md: '150px'
                                    }
                                }}
                            >
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="40px" fontWeight={700}>
                                        Success!
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        Your business travel insurance is confirmed. Your HR department will be notified
                                        of your booking and will coordinate with us directly.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        You'll receive your Confirmation of Insurance Coverage (COIC) via email shortly.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        For any emergencies during your trip, contact our 24/7 medical assistance
                                        hotline at{' '}
                                        <a href="tel:+63282313922" style={{ color: '#fff' }}>
                                            +63 2 8231 3922
                                        </a>
                                        . Please save this number ahead of your trip. Long-distance charges may apply.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        Safe travels ahead!
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12, md: 3 }}>
                                    <ButtonComponent
                                        color="#fff"
                                        background="#ff6002"
                                        label="Return Home"
                                        handleClick={() => {
                                            window.location.reload();
                                            setConfig((c) => ({ ...c, page: 'landing' }));
                                        }}
                                    />
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>
                )}

                {config.applicationStatus === 'WP' && (
                    <Grid2 size={{ xs: 11, md: 8 }} style={{ zIndex: '999' }}>
                        <>
                            <Grid2
                                container
                                flexGrow={1}
                                spacing={3}
                                sx={{
                                    paddingTop: {
                                        xs: '20px',
                                        md: '60px'
                                    },
                                    paddingBottom: {
                                        xs: '20px',
                                        md: '60px'
                                    }
                                }}
                            >
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="40px" fontWeight={700}>
                                        Success!
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        Thank you for your payment. Your travel insurance is now confirmed.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        You'll receive your Confirmation of Insurance Coverage (COIC) via email shortly.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        For any emergencies during your trip, contact our 24/7 medical assistance
                                        hotline at{' '}
                                        <a href="tel:+63282313922" style={{ color: '#fff' }}>
                                            +63 2 8231 3922
                                        </a>
                                        . Please save this number ahead of your trip. Long-distance charges may apply.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        Safe travels ahead!
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12, md: 3 }}>
                                    <ButtonComponent
                                        color="#fff"
                                        background="#ff6002"
                                        label="Return Home"
                                        handleClick={() => setConfig((c) => ({ ...c, page: 'landing' }))}
                                    />
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>
                )}

                {config.applicationStatus === 'F' && (
                    <Grid2 size={{ xs: 11, md: 8 }} style={{ zIndex: '999' }}>
                        <>
                            <Grid2
                                container
                                flexGrow={1}
                                spacing={3}
                                sx={{
                                    paddingTop: {
                                        xs: '20px',
                                        md: '60px'
                                    },
                                    paddingBottom: {
                                        xs: '20px',
                                        md: '60px'
                                    }
                                }}
                            >
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="40px" fontWeight={700}>
                                        Payment Unsuccessful.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        Oops! It looks like your payment didn't go through.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        Please try again or use a different payment method.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        If the issue persists, feel free to reach out to helpline@bpims.com or call For
                                        any emergencies during your trip, contact our 24/7 medical assistance hotline at{' '}
                                        <a href="tel:+63282313922" style={{ color: '#fff' }}>
                                            +63 2 8231 3922
                                        </a>{' '}
                                        for assistance.
                                    </Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12, md: 3 }}>
                                    <Typography color="#fff" fontSize="20px">
                                        <ButtonComponent
                                            color="#fff"
                                            background="#ff6002"
                                            label="TRY AGAIN"
                                            handleClick={() => setConfig((c) => ({ ...c, page: 'landing' }))}
                                        />
                                    </Typography>
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>
                )}

                <Grid2
                    size={{ md: 12 }}
                    position="absolute"
                    sx={{
                        background: 'transparent !important',
                        display: {
                            xs: 'none',
                            md: 'flex'
                        }
                    }}
                >
                    <img src={require('./../../../assets/images/wave.png')} style={{ width: '100%', opacity: '3%' }} />
                </Grid2>

                <Grid2 size={{ md: 12 }} sx={{ background: '#fff', paddingTop: '100px' }}>
                    <>
                        <Grid2 container alignContent="center" justifyContent="center" spacing={5}>
                            <Grid2 size={{ xs: 11, md: 8 }}>
                                <>
                                    <Grid2 container alignContent="center" justifyContent="center" spacing={5}>
                                        <Grid2 size={{ md: 12 }}>
                                            <Typography textAlign="center" fontSize="40px" fontWeight={600}>
                                                Need help with a claim?
                                            </Typography>
                                        </Grid2>
                                        <Grid2 size={{ md: 4 }}>
                                            <ButtonComponent
                                                color="#fff"
                                                background="#ff6002"
                                                label="FILE A CLAIM"
                                                handleClick={() => setConfig((c) => ({ ...c, modal: 'file-claim' }))}
                                            />
                                        </Grid2>
                                        <Grid2 size={{ md: 12 }} display="flex" justifyContent="center">
                                            <img src={require('./../../../assets/images/horizontal-divider.png')} />
                                        </Grid2>
                                        <Grid2>
                                            <Typography textAlign="center" fontSize="40px" fontWeight={600}>
                                                Got questions?
                                            </Typography>
                                        </Grid2>
                                        <Grid2>
                                            <Typography
                                                fontSize="20px"
                                                textAlign="center"
                                                sx={{ marginBottom: '30px' }}
                                            >
                                                Check out our{' '}
                                                <a href="https://bit.ly/AyalaTravelFAQ" target="_blank">
                                                    Frequently Asked Questions
                                                </a>{' '}
                                                to learn more about your policy and claims process. We're here to help
                                                make things as easy as possible.
                                            </Typography>
                                            <Typography fontSize="20px" textAlign="center">
                                                For further concerns, please contact the BPI MS Helpline via{' '}
                                                <a href="tel:63288409000" style={{ color: 'black' }}>
                                                    (632) 8840 9000
                                                </a>{' '}
                                                or helpline@bpims.com.
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>
                        </Grid2>
                    </>
                </Grid2>
            </Grid2>
        </>
    );
}

export default TransactionView;
