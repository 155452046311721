import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Grid2,
    styled,
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Button
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

const DRAWER_WIDTH = 240; // Changed to uppercase for constants
const NAV_ITEMS = [
    { label: 'ABOUT TRAVEL CARE', url: 'https://bpims.com/about-agtc', action: 'openUrl' },
    { label: 'FILE A CLAIM', action: 'openClaimModal' },
    { label: 'FAQs', url: 'https://bit.ly/AyalaTravelFAQ', action: 'openUrl' },
    { label: 'CONTACT US', action: 'scrollToFooter' }
      ];

const StyledAppBar = styled(AppBar)`
    // background: rgba(255, 255, 255, 0.7) !important;
`;

const StyledImageContainer = styled(Box)`
    align-content: center !important;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    img {
        max-height: 60px;
        flex-shrink: 0;
        max-width: 100%; /* Prevents images from overflowing */
    }

    @media (max-width: 600px) {
        margin-left: auto; 
        
        img {
            max-height: 35px; 
            max-width: 100%; 
        }

        img[alt="BPI MS Logo"] {
            max-height: 25px;
        }
            
        img[alt="Ayala Logo"] {
            max-height: 30px;
        }
    }
`;
   
const NavigationContainer = styled(Box)`
display: flex;
justify-content: flex-end;
flex-grow: 1;
padding: 0 5%;
max-width: calc(100% - 320px);
overflow: hidden;
       
@media (max-width: 900px) {
display: none;
}
`;

function DrawerAppBar(props) {
    const { window, handleShowClaimModal = () => {}, refWindow } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleNavAction = (item) => {
        const actionMap = {
            openUrl: () => refWindow.open(item.url, '_blank'),
            openClaimModal: handleShowClaimModal,
            scrollToFooter: () => scrollToElement('footer')
        };
        actionMap[item.action]?.();
    };
    
    // const openUrl = () => {
    //     window.open('https://b   pims.com/about-agtc', '_blank');
    // };
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Divider />
            <List>
            {NAV_ITEMS.map((item) => (
                <ListItem key={item.label} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleNavAction(item)}>
                        <ListItemText primary={item.label} />
                    </ListItemButton>
                </ListItem>
            ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <StyledAppBar
                component="nav"
                sx={{
                    position: {
                        xs: 'static',
                        md: 'absolute'
                    },
                    background: {
                        xs: '#fafcfc',
                        md: 'rgba(255, 255, 255, 0.9)'
                    }
                }}
            >
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
  		                aria-label="open drawer"
  		                edge="start"
  		                onClick={handleDrawerToggle}
  		                sx={{ display: { xs: 'flex', md: 'none' } }}
  		                 >
  		                <MenuIcon style={{ color: '#13462' }} />
  		            </IconButton>
                        <StyledImageContainer>
                            <img src={require('./../../../assets/images/ayala.png')} alt="Ayala Logo" />    
                            <img src={require('./../../../assets/images/divider.png')} alt="Divider" />
                            <img src={require('./../../../assets/images/bpims.png')} alt="BPI MS Logo" />
                        </StyledImageContainer>
                    <NavigationContainer>
                        {NAV_ITEMS.map((item) => (
                            <Button
                                key={item.label}
                                sx={{ color: '#000', fontSize: '20px', margin: '20px', flexShrink: 0 }}
                                onClick={() => handleNavAction(item)}
                            >
                                {item.label}
                            </Button>
                        ))}
                   </NavigationContainer>
  		  
                    {/* Mobile icon for the menu */}
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                    >
                    </IconButton>
                </Toolbar>
            </StyledAppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH }
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

DrawerAppBar.propTypes = {
    window: PropTypes.func,
  	handleShowClaimModal: PropTypes.func,
  	refWindow: PropTypes.object
};

export default DrawerAppBar;
