import { orderBy } from 'lodash';

import moment from 'moment';

function HandleGetPremium(maintenance = [], data) {
    const {
        type: travelTypeCd,
        groupSize: travelGroupSizeCd,
        plan: travelPlanCd,
        geoCoverage: travelGeoCoverageCd,
        transportMode: travelTransportModeCd,
        durationTypeCd: durationTypeCd,
        maxTripDuration: durationSelectionCd,
        durationDays: days,
        startDate,
        endDate
    } = data;
    let premium;

    const premiums = maintenance.filter(
        (m) =>
            travelTypeCd === m.travelTypeCd &&
            travelGroupSizeCd === m.travelGroupSizeCd &&
            travelPlanCd === m.travelPlanCd &&
            travelGeoCoverageCd === m.travelGeoCoverageCd &&
            travelTransportModeCd === m.travelTransportModeCd
    );

    if (durationTypeCd) {
        premium = premiums.filter(
            (f) =>
                durationTypeCd === f.durationTypeCd && durationSelectionCd === f.durationSelectionCd && days === f.days
        );
    } else if (premiums.length === 1) {
        premium = premiums;
    } else {
        const duration = moment.duration(moment(new Date(endDate)).diff(moment(new Date(startDate))));
        const _days = duration.asDays() + 1;
        const sortedPremiums = orderBy(premiums, ['days'], ['asc']);
        premium = sortedPremiums.find((f) => _days <= f.days);
        premium = [premium];
    }

    return (premium ?? []).pop()?.premAmt || 0;
}

export default {
    premium: HandleGetPremium
};
