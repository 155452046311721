import React from 'react';
import styled from 'styled-components';
import { Grid2, Typography } from '@mui/material';

import { DetailScaffold } from '../..';

const StyledText = styled(Typography)`
    font-size: 18px !important;
    color: #fff !important;
`;

const StyledTitle = styled(StyledText)`
    font-size: 33px !important;
    font-weight: 700 !important;
`;

const StyledDisclaimerText = styled(Typography)`
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #fff !important;
    text-align: center;
`;

function MainView() {
    return (
        <Grid2
            container
            direction="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            gap={5}
            sx={{
                padding: {
                    xs: '20px 0',
                    md: '100px 0'
                }
            }}
        >
            <Grid2 size={{ xs: 11, md: 6 }}>
                <Grid2 container direction="column" gap={3}>
                    <Grid2>
                        <StyledTitle>Let’s get your flight details right</StyledTitle>
                    </Grid2>
                    <Grid2>
                        <StyledText>
                            Get hassle-free travel coverage with <strong>Ayala Group Travel Care</strong>, starting at
                            just <strong>PHP 72.80!</strong> From medical emergencies to trip cancellations, our
                            insurance keeps you protected—quick, easy, and comprehensive.
                        </StyledText>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 size={{ xs: 11, md: 6 }}>
                <DetailScaffold />
            </Grid2>
            <Grid2 size={{ xs: 11, md: 6 }}>
                <StyledDisclaimerText>
                    <strong>If you are traveling as a group to the EU</strong>, please be informed that each group
                    member must file their own Confirmations of Insurance Coverage (COICs).
                </StyledDisclaimerText>
                <StyledDisclaimerText>
                    Group travel options must not be selected, in order to comply with Schengen regulations.
                </StyledDisclaimerText>
            </Grid2>
        </Grid2>
    );
}

export default MainView;
