import React from 'react';
import { Typography, Grid, useMediaQuery, useTheme, Hidden } from '@mui/material';
import planeImage from './../../../assets/images/plane.svg';
import { AppContext } from '../../../services';

const PlaneIcon = ({ filter }) => {
    return (
        <img
            src={planeImage}
            alt="plane"
            style={{
                margin: '0 10px',
                width: '24px',
                height: 'auto',
                filter: filter // Apply the passed filter
            }}
        />
    );
};

function PlaneView() {
    const { config } = React.useContext(AppContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const isLandingPage = config.page === 'landing';
    const isFormPage = config.page === 'form';
    const isTransactionPage = config.page === 'transaction';
    const isPayment = config.page === 'payment';

    // Define the filters
    const whiteFilter = 'brightness(0) saturate(100%) invert(99%) sepia(3%) saturate(2625%) hue-rotate(187deg) brightness(118%) contrast(80%)';
    const noFilter = '';

    // Determine the filter for PlaneIcon based on the current page
    const getFilter = () => {
        if (isLandingPage) return whiteFilter;
        if (isFormPage) return noFilter;
        if (isPayment) return noFilter; // Default color
        if (isTransactionPage) return noFilter; // Default color
        return noFilter; // Default filter for all other cases
    };

    const planeFilter = getFilter();
    const isPageTransaction = isTransactionPage;

    return (
        <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}
        style={{
            justifyContent: isMobile ? 'flex-start' : 'center',
            alignItems: isMobile ? 'flex-start' : 'center',
            marginLeft: isMobile ? '14px' : '0' // Adjust margin for mobile view
        }}>
            <Grid item xs={12} sm="auto" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                    Travel Insurance:
                </Typography>
            </Grid>

            {/* Trip Details block with landing page filter */}
            <Grid item xs={12} sm="auto" style={{ display: 'flex', alignItems: 'center' }}>
                <PlaneIcon filter={isLandingPage ? noFilter : ''} />
                <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                    Trip Details
                </Typography>
            </Grid>

            {/* Insured Information block */}
            <Grid item xs={12} sm="auto" style={{ display: 'flex', alignItems: 'center' }}>
            {isMobile ? null : (
                    <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                        {' . . . . . . . . . . . '}
                    </Typography>
                )}
                <PlaneIcon filter={isFormPage ? noFilter : planeFilter} />
                <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                    Insured Information
                </Typography>
            </Grid>

            {/* Payment block */}
            <Grid item xs={12} sm="auto" style={{ display: 'flex', alignItems: 'center' }}>
                 {isMobile ? null : (
                    <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                        {' . . . . . . . . . . . '}
                    </Typography>
                )}
                <PlaneIcon filter={!isTransactionPage ? (isPayment ? noFilter : whiteFilter) : ''} />
                <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                    Payment
                </Typography>
            </Grid>

            {/* Confirmation of Insurance Coverage block */}
            <Grid item xs={12} sm="auto" style={{ display: 'flex', alignItems: 'center' }}>
	                 {isMobile ? null : (
                    <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                        {' . . . . . . . . . . . '}
                    </Typography>
                     )}
                <PlaneIcon filter={isTransactionPage ? noFilter : whiteFilter} />
                <Typography variant="body1" style={{ color: 'white', whiteSpace: 'nowrap' }}>
                    Confirmation of Insurance Coverage
                </Typography>
            </Grid>
        </Grid>
    );
}

export default PlaneView;
