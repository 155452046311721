import React from 'react';
import { Grid2, Typography, styled } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

import { ButtonComponent, ModalComponent } from '../..';

const StyledContainer = styled(Grid2)`
    padding: 50px !important;
`;

const StyledIcon = styled(HighlightOff)`
    font-size: 98px !important;
`;

function GoingBackModal(props) {
    const { open, confirmGoBack = () => {}, cancelGoBack = () => {} } = props;

    return (
        <ModalComponent
            open={open}
            confirmGoBack={confirmGoBack}
            cancelGoBack={cancelGoBack}
            sx={{
                maxWidth: {
                    xs: '80vw',
                    md: '30vw'
                },
                minWidth: {
                    xs: '95vw',
                    md: '30vw'
                },
                maxHeight: {
                    xs: '85vh',
                    md: '70vh'
                },
                overflow: {
                    xs: 'auto',
                    md: 'auto'
                }
            }}
        >
            <StyledContainer container rowSpacing={2}>
                <Grid2 size={{ xs: 12 }} display="flex" justifyContent="center">
                    <StyledIcon />
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <Typography textAlign="center">Your progress will be lost</Typography>
                    <Typography textAlign="center">Do you want to continue?</Typography>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <ButtonComponent handleClick={confirmGoBack} label="CONFIRM" />
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <ButtonComponent handleClick={cancelGoBack} label="CANCEL" />
                </Grid2>
            </StyledContainer>
        </ModalComponent>
    );
}

export default GoingBackModal;
