import react from 'react';
import styled from 'styled-components';
import { Grid2, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

const StyledGridIcon = styled(Grid2)`
    margin-right: 10px;
`;

const StyledGridContainer = styled(Grid2)`
    background: #edf1f6;
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.8);
    overflow: hidden;
`;

const StyledText = styled(Typography)`
    font-size: 12px !important;
    font-weight: 600 !important;
    color: ${(props) => (props.$isActive ? '#FFFFFF' : '#A7A9AC')};
`;

const StyledIcon = styled(Check)`
    color: ${(props) => (props.$isActive ? '#FFFFFF' : 'transparent')};
`;

const StyledMenuItem = styled(Grid2)`
    background: ${(props) => (props.$isActive ? '#013462' : '#edf1f6')};
    padding: 5px 0;
    cursor: pointer;
`;

function MenuItem(props) {
    const { isActive = false, label, handleSelect = () => {} } = props;
    return (
        <StyledMenuItem
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            onClick={handleSelect}
            size={{ xs: 6 }}
            $isActive={isActive}
        >
            <StyledGridIcon>
                <StyledIcon $isActive={isActive} />
            </StyledGridIcon>
            <Grid2>
                <StyledText $isActive={isActive}>{label}</StyledText>
            </Grid2>
        </StyledMenuItem>
    );
}

function Menu({ items = [] }) {
    return (
        <StyledGridContainer container direction="row" padding={0} gap={0} size={{ xs: 12 }}>
            {items.map((item, index) => (
                <MenuItem {...item} key={`${uuidv4()}-menu-item-${index}`} />
            ))}
        </StyledGridContainer>
    );
}

export default Menu;
