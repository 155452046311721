import React from 'react';
import styled from 'styled-components';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Grid2, Typography } from '@mui/material';

const StyledCheckedIcon = styled(RadioButtonChecked)`
    color: #fff;
    font-size: x-large !important;
`;

const StyledUncheckedIcon = styled(RadioButtonUnchecked)`
    color: #000;
    font-size: x-large !important;
`;

const StyledText = styled(Typography)`
    font-size: 12px !important;
    line-height: 26px !important;
    color: ${(props) => (props.$checked ? '#fff' : '#000')};
`;

const StyledContainer = styled(Grid2)`
    background: ${(props) => (props.$checked ? '#002444' : '#EDF1F6')};
    border-radius: 8px;
    padding: 7px 0;
    overflow: hidden;
    border: solid 1px rgba(0, 0, 0, 0.7);
    cursor: pointer;
`;

const StyledIconContainer = styled(Grid2)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

function RadioComponent(props) {
    const { checked = false, label, handleSelect = () => {} } = props;
    return (
        <StyledContainer container $checked={checked} onClick={handleSelect}>
            <StyledIconContainer size={{ xs: 2 }}>
                {!!checked ? <StyledCheckedIcon /> : <StyledUncheckedIcon />}
            </StyledIconContainer>
            <Grid2 size="grow">
                <StyledText $checked={checked}>{label}</StyledText>
            </Grid2>
        </StyledContainer>
    );
}

export default RadioComponent;
