import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Grid2 } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import { InputComponent } from '..';

const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`;

const StyledIconContainer = styled.div`
    display: flex;
    flex: 1;
    flex-shrink: 1;
    background: #013462;
    cursor: pointer;
`;

const StyledMinus = styled(Remove)`
    color: #fff;
    font-size: x-large !important;
    align-self: center;
`;

const StyledPlus = styled(Add)`
    color: #fff;
    font-size: x-large !important;
    align-self: center;
`;

function ControllerComponent(props) {
    const { handleOnChanges = () => {}, value = '', handleOnClick = undefined, disabled = false, error, max } = props;
    const wrapperRef = React.useRef(null);

    const [internalValue, setInternalValue] = React.useState(0);

    React.useEffect(() => {
        setInternalValue(value);
    }, [value]);

    function handleControllerComponent() {
        if (disabled) return;
        if (handleOnClick) return handleOnClick();
    }

    return (
        <StyledWrapper ref={wrapperRef} onClick={handleControllerComponent}>
            <StyledIconContainer
                onClick={() => handleOnChanges(value ? (Number.isFinite(value) && value >= 0 ? value - 1 : 0) : 0)}
            >
                <StyledMinus />
            </StyledIconContainer>
            <InputComponent
                padding="12px 0 10px 0"
                hideDecoration
                value={internalValue}
                error={error}
                onChange={(e) => {
                    if (/^[0-9]\d*$/.test(e?.target?.value || -1)) {
                        if (parseInt(e?.target?.value) < max) {
                            handleOnChanges(parseInt(e?.target?.value));
                        }
                    } else if (!(e?.target?.value || false)) handleOnChanges(0);
                }}
            />
            <StyledIconContainer
                onClick={() => handleOnChanges(value ? (Number.isFinite(value) && value < max ? value + 1 : value) : 1)}
            >
                <StyledPlus />
            </StyledIconContainer>
        </StyledWrapper>
    );
}

export default ControllerComponent;
