import BaseRequest from './base';

function MaintenanceService() {
    const baseUrl = process.env.REACT_APP_API_URL;
    return BaseRequest(`${baseUrl}/travel/data/get`, {
        tables: [
            'company',
            'travelGeoCoverage',
            'travelPlan',
            'travelTransportMode',
            'travelType',
            'travelDurationSelection',
            'travelDurationTypePerDetails',
            'travelDurationType',
            'travelPremium',
            'travelPerils',
            'country',
            'barangay',
            'city',
            'province',
            'travelGroupSize',
            'parameters'
        ]
    });
}

export default MaintenanceService;
