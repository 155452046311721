function execute(url, file) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', file.type);

    const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: file
    };

    async function run() {
        return fetch(url, requestOptions)
            .then((response) => response)
            .catch((error) => console.error(error));
    }

    return run();
}

export default execute;
