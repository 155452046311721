import React from 'react';
import {
    Grid2,
    Typography,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress
} from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';

import { ButtonComponent, ModalComponent } from '../../';

import { AppContext } from '../../../services';

const StyledContainer = styled(Grid2)`
    padding: 60px 60px !important;
`;

function BookingModal(props) {
    const { open, handleClose = () => {}, onSubmit = () => {}, loading } = props;
    const { state, maintenance, form } = React.useContext(AppContext);
    let res;

    function handleDurationDate() {
        const { startDate, endDate } = state;
        if (startDate) {
            res = `${moment(new Date(startDate)).format('MMMM DD, YYYY')} - ${moment(new Date(endDate)).format(
                'MMMM DD, YYYY'
            )}`;
        } else {
            res = `${moment(new Date()).format('MMMM DD, YYYY')} -  ${moment(
                moment(new Date()).add(state?.plan === 'A' ? 365 : state.durationDays, 'days')
            ).format('MMMM DD, YYYY')}`;
        }

        return res;
    }

    function getTotalPremium() {
        // MOVE THIS TO PARAMS
        const groupSizeCdWithMultiplier = ['WC', 'G'];
        const { premAmount, ADULT, MINOR, principalCount = 1, groupSize } = state;
        const totalTravelers = principalCount + (ADULT || 0) + (MINOR || 0);

        return numeral(
            (groupSizeCdWithMultiplier.indexOf(groupSize) !== -1 ? totalTravelers : 1) * premAmount ?? 0
        ).format('0,0.00');
    }

    function getTravelers() {
        const { ADULT: adult, MINOR, principalCount = 1 } = state;
        const ADULT = (adult || 0) + principalCount;

        if (ADULT && !MINOR) {
            return `${ADULT} adult(s)`;
        } else if (!ADULT && MINOR) {
            return `${principalCount} adult(s) ${MINOR} minor(s)`;
        } else if (ADULT && MINOR) {
            return `${ADULT} adult(s), ${MINOR} minor(s)`;
        } else {
            return '';
        }
    }

    return (
        <ModalComponent
            open={open}
            handleClose={handleClose}
            sx={{
                width: {
                    xs: '100vw',
                    md: '50vw'
                },
                height: {
                    xs: '100vh',
                    md: '90vh'
                },
                maxHeight: '100vh',
                overflow: 'auto'
            }}
        >
            <StyledContainer container rowSpacing={6}>
                <Grid2 size={{ xs: 12 }}>
                    <Typography varint="h5" color="#013462" fontWeight={700} fontSize="36px">
                        Review Booking
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <>
                        <Grid2 container sx={{ flexGrow: 1 }} spacing={2}>
                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Travel Type
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                {
                                                    (
                                                        (maintenance?.travelType || []).find(
                                                            (f) => f.travelTypeCd === state.type
                                                        ) || {}
                                                    ).travelTypeDesc
                                                }
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>

                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Company Name
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                {
                                                    (
                                                        (maintenance?.company || []).find(
                                                            (f) => f.value === state.company
                                                        ) || {}
                                                    ).label
                                                }
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>

                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Destination
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                {
                                                    (
                                                        (maintenance?.country || []).find(
                                                            (f) => f.value === state.destination
                                                        ) || {}
                                                    ).label
                                                }
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>

                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Travel Dates
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                {handleDurationDate()}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>

                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Plan
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                {`${
                                                    (
                                                        (maintenance?.travelPlan || []).find(
                                                            (f) => f.travelPlanCd === state.plan
                                                        ) || {}
                                                    ).travelPlanDesc
                                                }, ${
                                                    (
                                                        (maintenance?.travelGeoCoverage || []).find(
                                                            (f) => f.travelGeoCoverageCd === state.geoCoverage
                                                        ) || {}
                                                    ).travelGeoCoverageDesc
                                                }, ${
                                                    (
                                                        (maintenance?.travelTransportMode || []).find(
                                                            (f) => f.travelTransportModeCd === state.transportMode
                                                        ) || {}
                                                    ).travelTransportModeDesc
                                                }`}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>

                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Travel Group Size
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                {
                                                    (
                                                        (maintenance?.travelGroupSize || []).find(
                                                            (f) => f.travelGroupSizeCd === state.groupSize
                                                        ) || {}
                                                    ).travelGroupSizeDesc
                                                }
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>

                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Total Premium
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                {getTotalPremium()}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>

                            <Grid2 size={{ xs: 12 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <Grid2 size={{ lg: 4, xs: 12 }}>
                                            <Typography fontSize="16px" fontWeight={500}>
                                                Travelers
                                            </Typography>
                                        </Grid2>

                                        <Grid2>
                                            <Typography fontSize="16px" fontWeight={700}>
                                                {getTravelers()}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>
                        </Grid2>
                    </>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ background: '#013462' }}>
                                        <Typography fontSize="18px" color="#fff" fontWeight={500}>
                                            Traveler Name
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" sx={{ background: '#013462' }}>
                                        <Typography fontSize="18px" color="#fff" fontWeight={500}>
                                            Birthdate
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" sx={{ background: '#013462' }}>
                                        <Typography fontSize="18px" color="#fff" fontWeight={500}>
                                            Passport Number
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {form.map(({ firstName, middleName, lastName, dob, idNo }, _index) => (
                                    <TableRow key={`booking-table-row-${_index}`}>
                                        <TableCell align="center">
                                            <Typography
                                                fontSize="18px"
                                                color="#A7A9AC"
                                                fontWeight={500}
                                                sx={{ textTransform: 'uppercase' }}
                                            >
                                                {[firstName, middleName, lastName].join(' ')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography fontSize="18px" color="#A7A9AC" fontWeight={500}>
                                                {moment(new Date(dob)).format('MM/DD/YYYY')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography fontSize="18px" color="#A7A9AC" fontWeight={500}>
                                                {idNo}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid2>

                <Grid2 size={{ xs: 12 }} sx={{ my: '50px' }}>
                    <>
                        <Grid2 container justifyContent="center" spacing={2}>
                            <Grid2 size={{ lg: 4, xs: 12 }}>
                                <ButtonComponent handleClick={handleClose} label="GO BACK & MODIFY DETAILS" />
                            </Grid2>
                            <Grid2 size={{ lg: 4, xs: 12 }}>
                                <ButtonComponent
                                    handleClick={onSubmit}
                                    color="#fff"
                                    background="#ff6002"
                                    label={loading ? <CircularProgress size="14px" /> : 'PROCEED'}
                                />
                            </Grid2>
                        </Grid2>
                    </>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Typography fontSize="16px" textAlign="center">
                        <strong>For business</strong> travel bookings, click 'Proceed' to confirm your booking with
                        HR—this will prompt a notification to your HR department.
                    </Typography>
                    <Typography fontSize="16px" textAlign="center">
                        <strong>For personal</strong> travel, clicking 'Proceed' will take you to the payment gateway.
                    </Typography>
                </Grid2>
            </StyledContainer>
        </ModalComponent>
    );
}

export default BookingModal;
